import {diff_match_patch} from "diff-match-patch";
const r3dDiffUrl = '/backend/repository/diff';

const hljs = require('highlight.js/lib/core');
hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'));
const r3dPreviewUrl = '/backend/repository/preview';


/**
 * Constructor
 *
 * @returns {Re3data}
 */
var Re3data = function() {
    var instance = null;
    if (Re3data.prototype.this !== "undefined"){
        instance = Re3data.prototype.this;
    }
    return instance;

};

Re3data.prototype.this = new Re3data();

Re3data.prototype.unsavedChanges = false;

Re3data.prototype.changed = function(status){
    if ( typeof status === 'boolean' ) {
        Re3data.prototype.unsavedChanges = status;
    };
    return Re3data.prototype.unsavedChanges;
};

//Remove the element from the collection
Re3data.prototype.deleteButtonAction = function (event) {
    //Remove the input element the button belongs to
    var entityId = $(this).data('entity');
    $('#' + entityId).remove();

    //Renumber the existing input elements, so there is no gap in the numbering
    var collectionId = $(this).data('collection');
    Re3data().changed(true);
    return false;
};

Re3data.prototype.changeTestStatusAction = function (event) {
    var checkbox = $(this);
    var testCaseKey = checkbox.data('test-case-key');
    var testName = checkbox.data('test-name');
    var isChecked = event.target.checked;


    checkbox.prop('disabled', true);

    $.ajax({
        type: 'POST',
        url: '/backend/quality/changeStatus',
        data: JSON.stringify({
            testCaseKey: testCaseKey,
            testName: testName,
            resolved: isChecked
        }),
        contentType: 'application/json',
        success: function (data) {
            console.log(data);
            checkbox.parents('.r3d-qualitylist').find('.r3d-qualitykey').css('color', 'gray');
            checkbox.prop('disabled', false);
        }
    });
};

Re3data.prototype.changeUrlCheckerStatusAction = function (event) {
    var checkbox = $(this);
    var id = checkbox.data('id');
    var isChecked = event.target.checked;

    checkbox.prop('disabled', true);

    $.ajax({
        type: 'POST',
        url: '/backend/quality/changeUrlCheckerStatus',
        data: JSON.stringify({
            id: id,
            resolved: isChecked
        }),
        contentType: 'application/json',
        success: function (data) {
            console.log(data);
            checkbox.prop('disabled', false);
        }
    });
};

Re3data.prototype.highlightLines = function () {
    var isOdd = false;
    var rows = $('.r3d-urlChecker-list > div.r3d-qualitylist');
    rows.each(function(i)
    {
        var row = $(rows[i]);
        row.removeClass('odd even');
        if (row.css('display') != 'none') {
            row.addClass(isOdd ? 'odd' : 'even');
            isOdd = !isOdd;
        }
    });
};

Re3data.prototype.toggleResolved = function () {
    $('.r3d-urlChecker-list > div.r3d-testcase-resolved').toggle(0);

    var isVisible = $('.r3d-urlChecker-list > div.r3d-testcase-resolved').is(':visible');
    $('.r3d-btn-urlchecker-resolved').text(isVisible ? 'Hide resolved' : 'Show resolved');

    Re3data().highlightLines();
};

//Add a new element to the collection
Re3data.prototype.addButtonAction= function (event) {

    //Get the collection id
    var collectionId = $(this).attr('data-collection');

    //Get the collection element
    var collection = $('#' + collectionId);

    //Get current index to use (initial value is number of childs)
    var index = collection.data('index');

    //Set index to 0 if it is not a number. This happens if new elements
    //with sub collections are added
    if (isNaN(index)) {
        index = 0;
    }
    //Increment the index to prevent entities/formelements with the same id
    collection.data('index', index + 1);

    //Read the prototype
    var prototype = collection.attr('data-prototype');

    //Replace the the placeholder for the counter within this prototype with
    //the index number so it can be referenced by js and the backend
    var regex = /((?:id|name|for|data-entity|data-collection)=".*?)__name__(.*?")/g;
    var substitution = "$1" + index + "$2";
    var prototype = prototype.replace(regex, substitution);

    //Replace the first occurence within the subprototypes
    var regex = /((?:id|name|for|data-entity)=&quot;.*?)__name__(.*?&quot;)/g;
    var substitution = "$1" + index + "$2";
    var form = prototype.replace(regex, substitution);

    //Add Element to the collection
    collection.append(form);

    //Register click event for the add-buttons in subforms (if any)
    var id = $(form).attr('id');
    $('#' + id + ' .col-sm-2 .r3d-btn-add-entity').click(Re3data().addButtonAction);

    //Register click event for the delete-button of this form and any in the
    //subforms (if any)
    $('#' + id + ' > .col-sm-2 .r3d-btn-del-entity').click(Re3data().deleteButtonAction);

    Re3data().changed(true);
    return false;
};

Re3data.prototype.getPreviewXML = function() {
    var form = $('[name="repository"]');
    //Get all form values
    var formValues = {};
    $.each(form.serializeArray(), function (i, formField) {
        formValues[formField.name] = formField.value;
    });
    $('#preview').text("Loading...");
    $.ajax({
        type: form.attr('method'),
        url: r3dPreviewUrl,
        data: formValues,
        success: function (data) {
            $('#preview').text(data);
            $('pre code').each(function(i, element) {
                // uses highlight.js library to highlight xml syntax
                hljs.highlightElement(element);
            });
        }
    });
};

Re3data.prototype.showDiffToOnline = function() {
    var form = $('[name="repository"]');
    //Get all form values
    var formValues = {};
    $.each(form.serializeArray(), function (i, formField) {
        formValues[formField.name] = formField.value;
    });

    var url = r3dDiffUrl;
    if(typeof formValues['repository[identifier][re3data]'] !== 'undefined'){
        url = r3dDiffUrl + '?id=' + formValues['repository[identifier][re3data]'];
    }

    $('#r3d-diff').text("Loading...");
    $.ajax({
        type: form.attr('method'),
        url: url,
        data: formValues,
        success: function (data) {
            var diffMatchPatch = new diff_match_patch();
            var diff = diffMatchPatch.diff_main(data.onlineXML, data.previewXML);
            diffMatchPatch.diff_cleanupSemantic(diff);
            var prettyDiff = diffMatchPatch.diff_prettyHtml(diff);
            $('#r3d-diff').empty();
            $('#r3d-diff').append(prettyDiff);
        }
    });
};

Re3data.prototype.showQa = function() {
    var formValues = {};
    var form = $('[name="repository"]');
    $.each(form.serializeArray(), function (i, formField) {
        formValues[formField.name] = formField.value;
    });

    $.ajax({
        type: 'POST',
        url: r3dQaUrl,
        data: formValues,
        success: function (data) {
            $('#r3d-qa').empty();
            $('#r3d-qa').append(data);
        }
    });
};

Re3data.prototype.preventBackspace = function (e) {
    //If key code is backspace and it is not within a textarea or input field
    if (e.which === 8 && !$(e.target).is("form :input")) {
        //Do not further process the click
        e.stopPropagation();
        e.preventDefault();
    }
};

Re3data.prototype.resizeEditorControls = function (){
    if($('#r3d-editor').length === 1 && $('#controls').length === 1){
        $('#controls').width($('#r3d-editor').width());
        $('#r3d-editor').css('margin-bottom', Math.round($('#controls').height()) + 'px');
    }
};

// Fade in the navbar on scroll for the start page
Re3data.prototype.collapseNavbar = function () {
    var navbar = $(".r3d-startpage-background .navbar");
    if ((navbar.length === 1 && navbar.offset().top > 50)
            || $('#r3d-menu').attr('aria-expanded') == "true") {
        navbar.removeClass("r3d-navbar-transparent");
    } else {
        navbar.addClass("r3d-navbar-transparent");
    }
};


$(document).ready(function () {

    //Initialize navbar fading
    $(window).scroll(Re3data().collapseNavbar);
    $(document).ready(Re3data().collapseNavbar);

    $('#r3d-menu').on('show.bs.collapse', function () {
        $(".r3d-startpage-background .navbar").removeClass("r3d-navbar-transparent");
    });
    $('#r3d-menu').on('hidden.bs.collapse', function () {
        Re3data().collapseNavbar();
    });

    //Register click events
    $('.r3d-btn-add-entity').click(Re3data().addButtonAction);
    $('.r3d-btn-del-entity').click(Re3data().deleteButtonAction);
    $('.r3d-btn-urlchecker-resolved').click(Re3data().toggleResolved);
    // $('.r3d-btn-scroll-publications').click(Re3data().scrollPublications);

    //Register change events
    $('.r3d-cbx-resolve').on('ifChanged', Re3data().changeTestStatusAction);
    $('.r3d-cbx-resolveUrlChecker').on('ifChanged', Re3data().changeUrlCheckerStatusAction);

    // Set initial index for the data-collections,
    // so adding new children will start with the correct numbering
    $('div[data-prototype]').each(function () {
        $(this).data('index', $(this).children().length);
    });

    // Trigger js calls for the preview and diff tab
    $('#previewTab').on('click', Re3data().getPreviewXML);
    $('#diffTab').on('click', Re3data().showDiffToOnline);
    $('#qaTab').on('click', Re3data().showQa);

    //Disable backspace key resulting in moving back in the browser history
    //This doesn't work in chrome for select boxes :/
    if($("form[name='repository']").length !== 0){
       $(document).on("keypress", Re3data().preventBackspace);
       $(document).on("keydown", Re3data().preventBackspace);
    }
    //Show an alert with the option to cancel if the user has changed something
    $('form[name="repository"] :input').on("change", function () {
        Re3data().changed(true);
    });

    $('form[name="suggest"] :input').on("change", function () {
        Re3data().changed(true);
    });

    //Prevent the "unsaved changes"-popup on clicking on of the submit buttons
    $('form[name="repository"]').on("submit",function(){
        Re3data().changed(false);
    });

    $('form[name="suggest"]').on("submit",function(){
        Re3data().changed(false);
    });

    window.onbeforeunload = function (event) {
        if (Re3data().changed()) {
            return "There are unsave changes. Are you sure that you want to leave?";
        }
    };

    // Set the size of the fixed controls within the editor
    Re3data().resizeEditorControls();
    // Bind the size calculation to the resize event
    $( window ).resize(Re3data().resizeEditorControls);

    Re3data().highlightLines();
});